import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Container,
  Box,
} from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';
import ContactUs from '../components/ContactUs/ContactUs';
import { Link } from 'react-router-dom';
import PaymentForm from 'components/PaymentForm/PaymentForm';
import investorGuide from '../Files/investor-guide.pdf'; // Ensure correct import

const Disclaimer = () => {
  const footerRef = useRef(null);

  const handleContactUsClick = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDownload = (e) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = investorGuide;
    link.download = 'investor-guide.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", gap:"24px", background: `linear-gradient(90deg, rgba(209,230,226,1) 0%, rgba(234,238,236,1) 95%)`, overflowY: "scroll"}}>
     <Helmet>
        <title>InvestoFarms</title>
        <meta name="Investo Farms | Invest Today" content="Discover agriculture investment opportunities with InvestFarms Today. Start investing in agriculture and secure your financial future. Smart farming, Smarter investment." />
      </Helmet>
      <Box sx={{ height:"inherit"}}>
        <Navbar onContactUsClick={handleContactUsClick}/>
      </Box>
      <Container maxWidth="lg" sx={{ height:"100%"}}>
        <Box sx={{ display:"flex", flexDirection:"column", gap:"16px"}}>
          <Box>
            <Typography sx={{fontSize:{xs:"20px",md:"24px"}, fontWeight:"bold", color:"#28947F", textAlign:"center"}}>
              Investment Disclaimer
            </Typography>
            <Typography sx={{fontSize:"12px", textAlign:"center"}}>
              Important Information Before You Invest
            </Typography>
          </Box>
          <Typography sx={{fontSize:"11px"}}>
            Thank you for your interest in investing with InvestoFarms! Before proceeding, we kindly ask you to review the following important information
          </Typography>
          <Box sx={{ display:"flex", flexDirection:"column", gap:"8px"}}>
            <Typography sx={{ fontSize: "11px" }}>
              <b>Non-Equity Investment:</b> InvestoFarms offers non-equity investment opportunities in managed farmlands. You will not directly own or have any legal title to the land. Your investment will be tied to the overall performance of the farm(s).
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              <b>Passive Income:</b> Our investment model aims to generate passive income for investors. However, there is no guarantee of returns, and your investment is subject to inherent risks associated with agriculture.
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              <b>Risk Factors:</b> Farmland investment carries inherent risks such as weather fluctuations, market conditions, and potential for crop failure. We recommend you carefully consider your investment goals, risk tolerance, and financial situation before investing.
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              <b>Investment Minimums:</b> There is a minimum investment of ₹25,000 required to participate. Please note that if a refund is requested within a week, ₹2,000 will be deducted as processing fees. Please refer to the specific investment details for more information.
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              <b>Investment Term:</b> Investments with Investo Farms typically have a lock-up period where your funds are committed for a set timeframe. Please review the specific investment offering for details on the term length.
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              <b>Regulatory Compliance:</b> Investo Farms strives to comply with all applicable regulations. However, investment regulations may vary based on your location. Please ensure you are eligible to invest based on your residency and other relevant regulations.
            </Typography>
            <Typography sx={{ fontSize: "11px" }}>
              <b>Independent Review:</b> We encourage you to conduct your own independent research and due diligence before making any investment decisions.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt:"20px", display:"flex", flexDirection:"column", gap:"8px"}}>
          <Typography sx={{fontSize:{xs:"20px",md:"24px"}, fontWeight:"bold", color:"#28947F", textAlign:"center"}}>
            Additional Resources
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            We recommend reviewing our <Link style={{color:"#28947F", fontWeight:"bold"}} to="/faq">Frequently Asked Questions (FAQ)</Link> section for further information
          </Typography>
          {/* <Typography sx={{ fontSize: "12px" }}>
            You can also download our <Link style={{color:"#28947F", fontWeight:"bold"}} to="#" onClick={handleDownload}>Investor Guide</Link> for a more detailed explanation of our investment model
          </Typography> */}
        </Box>
        <Box sx={{ mt:"20px", display:"flex", flexDirection:"column", gap:"8px"}}>
          <Typography sx={{fontSize:{xs:"20px",md:"24px"}, fontWeight:"bold", color:"#28947F", textAlign:"center"}}>
            Disclaimer
          </Typography>
          <Typography sx={{fontSize:"12px"}}>
            Investing in farmland involves risks, and there is no guarantee of profit. Past performance is not necessarily indicative of future results. The information provided on this page is for informational purposes only and should not be considered financial advice. Please consult with a financial advisor before making any investment decisions.
          </Typography>
          <Typography sx={{fontSize:"12px"}}>
            By proceeding to the payment gateway, you acknowledge that you have read and understood the information presented on this page.
          </Typography>
        </Box>
        <Box>
          <PaymentForm />
        </Box>
      </Container>
      <Box sx={{mt:{xs:"480px",md:"0px"}}}>
        <ContactUs />
      </Box>
      <Box sx={{height:"inherit"}}>
        <Footer ref={footerRef} />
      </Box>
    </Box>
  );
};

export default Disclaimer;
